import React from "react";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import ErrorPage from "src/pages/ErrorPage";

import { ConceptBoard } from "@models/ConceptBoard";

import { useConceptBoardQuery } from "./data";

const ConceptBoardContext = React.createContext<ConceptBoard | null>(null);

export const ConceptBoardProvider = ({ children }) => {
  const { boardId } = useParams<{ boardId: string }>();
  const { data, error } = useConceptBoardQuery(boardId);

  if (error) return <ErrorPage error={error} />;

  // Todo, replace with Skeleton
  if (!data) return <CircularProgress />;

  return (
    <ConceptBoardContext.Provider value={data}>
      {children}
    </ConceptBoardContext.Provider>
  );
};

export const useConceptBoardContext = () => {
  const context = React.useContext(ConceptBoardContext);
  if (!context)
    throw new Error(
      "useConceptBoardContext must be used within a ConceptBoardProvider"
    );
  return context;
};
