import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ConceptBoard } from "@models/ConceptBoard";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const conceptBoardsKeyFactory = createQueryKeys("concept-boards", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<ConceptBoard>(`concept-boards/${id}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<ConceptBoard[]>("concept-boards", {
        params,
      }),
  }),
});

export const useConceptBoardQuery = (id?: string | null) => {
  return useQuery({
    ...conceptBoardsKeyFactory.detail(id!),
    enabled: !!id,
    structuralSharing: true,
  });
};

export const usePaginatedConceptBoardsQuery = buildPaginatedQuery(
  conceptBoardsKeyFactory.paginated,
  { structuralSharing: true }
);

export const useCreateConceptBoardMutation = () => {
  const queryClient = useQueryClient();
  const organizationId = useSelector(
    (state: any) => state.currentUser.organization.id
  );
  return useMutation({
    mutationFn: () =>
      client
        .post<ConceptBoard>("concept-boards", {
          title: "New Board",
          organization: { id: organizationId },
          relationshipNames: ["organization"],
        })
        .then((res) => res.data),
    onSuccess: (board) => {
      queryClient.invalidateQueries({
        queryKey: conceptBoardsKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        conceptBoardsKeyFactory.detail(board.id).queryKey,
        board
      );
    },
  });
};

type UpdateConceptBoardPayload = {
  id: string;
  title: string;
  description: string;
  budget: string;
  deadline: string | Date;
  supplierIds: string[];
  conceptCategoryIds: string[];
};

export const useUpdateConceptBoardMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      supplierIds,
      conceptCategoryIds,
      ...data
    }: UpdateConceptBoardPayload) =>
      client
        .update<ConceptBoard>(`concept-boards/${id}`, {
          ...data,
          suppliers: supplierIds.map((id) => ({ id, type: "supplier" })),
          conceptCategories: conceptCategoryIds.map((id) => ({
            id,
            type: "concept-category",
          })),
          relationshipNames: ["organization", "suppliers", "conceptCategories"],
        })
        .then((res) => res.data),
    onSuccess: (board) => {
      queryClient.invalidateQueries({
        queryKey: conceptBoardsKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        conceptBoardsKeyFactory.detail(board.id).queryKey,
        board
      );
    },
  });
};

export const useDeleteConceptBoardMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => client.delete(`concept-boards/${id}`),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({
        queryKey: conceptBoardsKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        conceptBoardsKeyFactory.detail(id).queryKey,
        null
      );
    },
  });
};
