/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import { FileCard } from "@features/cloudinaryFiles";
import { RectChip } from "@features/ui";

import { useConceptContext } from "../ConceptContext";
import ConceptsFavoriteToggle from "./ConceptsFavoriteToggle";

const ConceptReadOnlyInfo = ({ setInitialFilePreview }) => {
  const concept = useConceptContext();
  return (
    <div>
      <h1 tw="mb-1 text-3xl font-light px-3">
        {concept.title || <span tw="text-neutral-400">Untitled Concept</span>}
      </h1>
      <div tw="flex flex-wrap gap-x-2 gap-y-1 px-3">
        <RectChip tw="bg-purple-100 text-purple-800">
          {_.startCase(concept.status ?? "")}
        </RectChip>
        <ConceptsFavoriteToggle />
        {concept.conceptCategories.map((cat) => (
          <RectChip key={cat.id}>{cat.name}</RectChip>
        ))}
      </div>

      <p tw="text-neutral-500 whitespace-pre-wrap mx-3 mt-4 mb-6 max-w-prose">
        {concept.description || (
          <span tw="text-neutral-400">No Description</span>
        )}
      </p>
      <div tw="px-3 mb-6">
        <div tw="flex justify-between items-center mb-3">
          <h2 tw="text-lg font-medium text-neutral-700 ">Files</h2>
        </div>
        <div tw="flex flex-wrap gap-4">
          {concept.cloudinaryFiles.map((file, i) => (
            <FileCard
              key={file.id}
              file={file}
              onClick={() => setInitialFilePreview(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConceptReadOnlyInfo;
