/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Controller } from "react-hook-form";

import { Autocomplete, CircularProgress } from "@mui/material";

import { StyledTextInput } from "@utils/forms/ControlledInputs";

import {
  useConceptCategoriesQuery,
  useCreateConceptCategoryMutation,
} from "../data";

const ConceptCategoryAutocomplete = ({ control, name, label }) => {
  const createCategoryMutation = useCreateConceptCategoryMutation();
  const { data: categories = [] } = useConceptCategoriesQuery({});
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          {...field}
          size="small"
          onChange={async (event, newValue: any[]) => {
            field.onChange(newValue.map((v) => v.id ?? v));
          }}
          filterOptions={(options, params) => {
            const inputValue = params.inputValue.trim();

            const filtered = options.filter((option) =>
              inputValue !== ""
                ? option.name.toLowerCase().includes(inputValue.toLowerCase())
                : true
            );

            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue.toLowerCase() === option.name.toLowerCase()
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                id: "",
                name: `Add "${inputValue}"`,
                inputValue,
              } as any);
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={categories}
          getOptionLabel={(option: any) => {
            return categories.find((c) => c.id === option)?.name ?? "";
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                // override onClick to create the new value
                {...(option.inputValue && {
                  onClick: async function (e) {
                    e.stopPropagation();
                    const createdCat = await createCategoryMutation.mutateAsync(
                      option.inputValue
                    );
                    field.onChange([...field.value, createdCat.id]);
                  },
                })}
              >
                {option.name}
                {option.inputValue && createCategoryMutation.isPending && (
                  <CircularProgress size={16} sx={{ ml: 2 }} />
                )}
              </li>
            );
          }}
          disableClearable
          getOptionDisabled={(opt) =>
            field.value?.includes(opt.id) ||
            (opt.inputValue && createCategoryMutation.isPending)
          }
          multiple
          renderInput={(params) => (
            <StyledTextInput
              {...params}
              disabled={createCategoryMutation.isPending}
              label={label}
            />
          )}
        />
      )}
    />
  );
};

export default ConceptCategoryAutocomplete;
