import { Link } from "react-router-dom";

import ConceptBoard from "src/pages/concepts/ConceptBoard";
import ConceptBoardEdit from "src/pages/concepts/ConceptBoardEdit";
import CreateBoards from "src/pages/concepts/CreateBoards";

import { useConceptBoardQuery } from "@features/conceptBoards";

import { protectedRoute } from "../types";

const CurrentBoardLink = ({ boardId }) => {
  const { data } = useConceptBoardQuery(boardId as string);
  return (
    <Link to={`/create/boards/${boardId}`}>
      {data?.title || "Concept Board"}
    </Link>
  );
};

const boardLinkHandle = ({ boardId }) => <CurrentBoardLink boardId={boardId} />;

const createRouter: protectedRoute = {
  path: "create",
  children: [
    {
      path: "boards",
      handle: { crumb: () => "Concept Boards" },
      element: <CreateBoards />,
    },
    {
      path: "boards/:boardId",
      handle: { crumb: () => <Link to="/create">Concept Boards</Link> },
      children: [
        { path: "", element: <ConceptBoard /> },
        {
          path: "edit",
          handle: boardLinkHandle,
          element: <ConceptBoardEdit />,
        },
        {
          path: ":conceptId",
          handle: boardLinkHandle,
          element: <ConceptBoard />,
        },
      ],
    },
  ],
};

export default createRouter;
