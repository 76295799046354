/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Dialog, DialogContent } from "@mui/material";

import { CloudinaryFile } from "@models/CloudinaryFile";

import CloudinaryFileCarousel from "./CloudinaryFileCarousel";

const ImageCarousel = ({
  files,
  open,
  handleClose,
  startIndex,
}: {
  files: CloudinaryFile[];
  open: boolean;
  handleClose: () => void;
  startIndex?: number;
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent tw="p-6">
        <CloudinaryFileCarousel files={files} startIndex={startIndex} />
      </DialogContent>
    </Dialog>
  );
};

export default ImageCarousel;
