import React from "react";

import { CircularProgress } from "@mui/material";

import { Concept } from "@models/Concept";

import { useConceptQuery, useCurrentFilteredBoardConcepts } from "./data";

const ConceptContext = React.createContext<Concept | null>(null);

export const ConceptProvider = ({
  children,
  conceptId,
}: {
  conceptId: string;
  children: any;
}) => {
  const concepts = useCurrentFilteredBoardConcepts();
  const { data: concept } = useConceptQuery(conceptId, {
    initialData: () => concepts.find((concept) => concept.id === conceptId),
  });

  // if (error) return <ErrorPage error={error} />;

  // Todo, replace with Skeleton
  if (!concept) return <CircularProgress />;

  return (
    <ConceptContext.Provider value={concept}>
      {children}
    </ConceptContext.Provider>
  );
};

export const useConceptContext = () => {
  const context = React.useContext(ConceptContext);
  if (!context)
    throw new Error("useConceptContext must be used within a ConceptProvider");
  return context;
};
