import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Discussion, Message } from "@models/Discussion";
import client from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

import { useConceptContext } from "../ConceptContext";

export const discussionsKeyFactory = createQueryKeys("discussions", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Discussion>(`discussions/${id}`).then((res) => res.data),
  }),
  list: (conceptId: string) => ({
    queryKey: [conceptId],
    queryFn: () =>
      client
        .get<Discussion[]>("discussions", {
          params: { filter: { conceptId }, skipPagination: true },
        })
        .then((res) => res.data),
  }),
});

export const useDiscussionQuery = (
  id: string | null | undefined,
  conf?: QueryOptions<Discussion>
) => {
  return useQuery({
    ...discussionsKeyFactory.detail(id!),
    enabled: !!id,
    structuralSharing: true,
    ...conf,
  });
};

export const useDiscussionsListQuery = (
  conceptId?: string | null,
  options?: QueryOptions<Discussion[]>
) => {
  return useQuery({
    ...discussionsKeyFactory.list(conceptId!),
    structuralSharing: true,
    enabled: !!conceptId,
    ...options,
  });
};

export const useGetLatestDiscussionForConcept = (supplierId: string) => {
  const { id: conceptId } = useConceptContext();
  const queryClient = useQueryClient();
  return async function () {
    await queryClient.invalidateQueries({
      queryKey: discussionsKeyFactory.list(conceptId).queryKey,
    });
    const discussions =
      (await queryClient.getQueryData<Discussion[]>(
        discussionsKeyFactory.list(conceptId).queryKey
      )) ?? [];
    return discussions.find((d) => d.supplierId === +supplierId)?.id ?? null;
  };
};

type CreateDiscussionPayload = {
  conceptId: string;
  supplierId: string;
};

export const useCreateDiscussionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateDiscussionPayload) =>
      client
        .post<Discussion>("discussions", {
          __type: "discussion",
          ...data,
        })
        .then((res) => res.data),
    onSuccess: (item, { conceptId }) => {
      queryClient.invalidateQueries({
        queryKey: discussionsKeyFactory.list(conceptId).queryKey,
      });
      queryClient.setQueryData<Discussion>(
        discussionsKeyFactory.detail(item.id).queryKey,
        item
      );
    },
  });
};

type CreateMessagePayload = {
  message: string;
  discussionId: string;
};

export const useCreateMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateMessagePayload) =>
      client
        .post<Message>("messages", {
          __type: "message",
          ...data,
        })
        .then((res) => res.data),
    onSuccess: (msg, { discussionId }) => {
      queryClient.invalidateQueries({
        queryKey: discussionsKeyFactory.detail(discussionId).queryKey,
      });
      queryClient.setQueryData<Discussion>(
        discussionsKeyFactory.detail(discussionId).queryKey,
        (oldDiscussion) =>
          oldDiscussion && {
            ...oldDiscussion,
            messages: [...oldDiscussion.messages, msg],
          }
      );
    },
  });
};

type UpdateMessagePayload = {
  id: string;
  message: string;
  discussionId: string;
};

export const useUpdateMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, message }: UpdateMessagePayload) =>
      client
        .update<Message>(`messages/${id}`, { message })
        .then((res) => res.data),
    onSuccess: (msg, { discussionId }) => {
      queryClient.invalidateQueries({
        queryKey: discussionsKeyFactory.detail(discussionId).queryKey,
      });
      queryClient.setQueryData<Discussion>(
        discussionsKeyFactory.detail(discussionId).queryKey,
        (oldDiscussion) =>
          oldDiscussion && {
            ...oldDiscussion,
            messages: oldDiscussion.messages.map((m) =>
              m.id === msg.id ? msg : m
            ),
          }
      );
    },
  });
};
type DeleteMessagePayload = {
  discussionId: string;
  id: string;
};
export const useDeleteMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: DeleteMessagePayload) =>
      client.delete(`messages/${id}`),
    onMutate: ({ id, discussionId }) => {
      const queryKey = discussionsKeyFactory.detail(discussionId).queryKey;
      // opimistic update
      const snapshot = queryClient.getQueryData<Discussion>(queryKey);

      queryClient.setQueryData<Discussion>(
        queryKey,
        (prevData) =>
          prevData && {
            ...prevData,
            messages: prevData.messages.filter((m) => m.id !== id),
          }
      );

      return () => {
        queryClient.setQueryData<Discussion>(queryKey, snapshot);
      };
    },
    onSuccess: (_, { discussionId }) => {
      queryClient.invalidateQueries({
        queryKey: discussionsKeyFactory.detail(discussionId).queryKey,
      });
    },
    onError: (err, _, rollback) => {
      rollback?.();
      console.error(err);
    },
  });
};
