/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useForm } from "react-hook-form";

import { TextField } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { StrippedSupplier } from "@models/Supplier";

import { useConceptContext } from "../../ConceptContext";
import {
  useCreateDiscussionMutation,
  useCreateMessageMutation,
  useGetLatestDiscussionForConcept,
} from "../../data";

const DiscussionForm = ({
  discussionId,
  supplierId,
  handleCancel,
}: {
  discussionId?: string | null;
  supplierId: string;
  handleCancel: () => void;
}) => {
  const { id: conceptId } = useConceptContext();
  const getLatestDiscussion = useGetLatestDiscussionForConcept(supplierId);
  const createDiscussionMutation = useCreateDiscussionMutation();
  const createMessageMutation = useCreateMessageMutation();

  const isPending =
    createDiscussionMutation.isPending || createMessageMutation.isPending;

  const { register, handleSubmit } = useForm({
    defaultValues: { message: "" },
  });
  const handleSendMessage = async ({ message }) => {
    message = message.trim();
    if (!message) return;
    if (!discussionId) {
      discussionId = await getLatestDiscussion();
    }
    if (!discussionId) {
      const res = await createDiscussionMutation.mutateAsync({
        conceptId,
        supplierId: supplierId,
      });
      discussionId = res.id;
    }
    await createMessageMutation.mutateAsync({
      discussionId: discussionId,
      message,
    });
    handleCancel();
  };
  return (
    <form onSubmit={(e) => e.preventDefault()} tw="w-full">
      <TextField
        autoFocus
        multiline
        fullWidth
        disabled={isPending}
        {...register("message", {
          required: "You must enter a message to send.",
        })}
        placeholder="Type your message here..."
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Enter" && e.metaKey) {
            handleSubmit(handleSendMessage)(e);
          }
        }}
      />
      <div tw="mt-2 flex gap-2">
        <StyledButton
          cta
          size="small"
          loading={isPending}
          onClick={handleSubmit(handleSendMessage)}
        >
          Send
        </StyledButton>
        <StyledButton
          size="small"
          disabled={isPending}
          onClick={handleCancel}
          disableElevation
        >
          Cancel
        </StyledButton>
      </div>
    </form>
  );
};

const DiscussionFormPlaceholder = ({
  discussionId,
  supplier,
}: {
  discussionId?: string | null;
  supplier: StrippedSupplier;
}) => {
  const [editing, setEditing] = React.useState(false);
  if (editing)
    return (
      <DiscussionForm
        discussionId={discussionId}
        supplierId={supplier.id}
        handleCancel={() => setEditing(false)}
      />
    );

  return (
    <button
      tw="bg-neutral-100 text-neutral-400 w-full p-2 px-4 rounded-md text-left border border-neutral-200 hover:border-neutral-300 cursor-text"
      onClick={() => setEditing(true)}
    >
      Add a comment...
    </button>
  );
};

export default DiscussionFormPlaceholder;
