/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

type RectChipProps = React.HTMLAttributes<HTMLDivElement> & {
  onDelete?: () => void;
};

const RectChip = React.forwardRef<HTMLDivElement, RectChipProps>(
  ({ children, onDelete, ...props }, ref) => {
    return (
      <div
        ref={ref}
        css={[
          tw`text-sm rounded-sm bg-primary-100 text-primary-800 px-3 py-1.5 flex items-center`,
          props.onClick && tw`cursor-pointer hover:bg-primary-200`,
        ]}
        {...props}
      >
        {children}
        {onDelete && (
          <IconButton
            size="small"
            onClick={onDelete}
            tw="-m-2 ml-1 text-inherit!"
          >
            <Close tw="text-base" />
          </IconButton>
        )}
      </div>
    );
  }
);

export default RectChip;
