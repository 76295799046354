/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";

import _ from "lodash";

import { useCreateCloudinaryFileMutation } from "@features/cloudinaryFiles";
import { useCreateConceptMutation } from "@features/concepts";
import { useUploadFileToCloudinary } from "@services/cloudinary";
import asyncPool from "@utility/asyncPool";

const FullPageDropZone = () => {
  const { conceptId } = useParams();
  const { hash } = useLocation();
  const conceptHash = hash.slice(1);

  const uploadToCloudinary = useUploadFileToCloudinary();
  const createCloudinaryFileMutation = useCreateCloudinaryFileMutation();
  const createConceptMutation = useCreateConceptMutation();
  const [isDragging, setIsDragging] = useState(false);

  const upload = async (file: File) => {
    const result = await uploadToCloudinary(file);
    const cf = await createCloudinaryFileMutation.mutateAsync({
      fileInfo: result,
      // if we're in the context of the concept modal, upload the file to that concept
      conceptId: conceptHash,
    });
    if (!conceptHash) {
      // if we're not in the context of a concept modal, create a new concept for every file
      await createConceptMutation.mutateAsync({
        cloudinaryFileIds: [cf.id],
        parentConceptId: conceptId, // create parent relationship if in a parent-concept context
      });
    }
    return result.original_filename;
  };

  const handleFile = (file: File) => {
    upload(file).then(() => toast.success(`Uploaded ${file.name}`));
  };

  const onDrop = async (e: React.DragEvent<any>) => {
    e.preventDefault();
    setIsDragging(false);
    const fileArray = [...e.dataTransfer.files];
    await asyncPool(5, fileArray, handleFile);
  };

  const handleStartDragging = (e: DragEvent | React.DragEvent<any>) => {
    e.preventDefault();
    if (e.dataTransfer?.types.includes("Files")) {
      setIsDragging(true);
    }
  };

  const handleStopDragging = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener("dragenter", handleStartDragging);
    window.addEventListener("dragover", handleStartDragging);
    window.addEventListener("dragleave", handleStopDragging);

    return () => {
      window.removeEventListener("dragenter", handleStartDragging);
      window.removeEventListener("dragover", handleStartDragging);
      window.removeEventListener("dragleave", handleStopDragging);
    };
  }, []);

  return (
    <div
      onDragEnter={handleStartDragging}
      onDragLeave={handleStopDragging}
      onDragOver={handleStartDragging}
      onDrop={handleStopDragging}
      css={[
        tw`fixed inset-0 z-[2000] flex items-center justify-center p-12 transition-opacity opacity-0 pointer-events-none bg-neutral-900/50`,
        isDragging && tw`opacity-100 pointer-events-auto`,
      ]}
    >
      <div
        onDrop={onDrop}
        // onDragLeave={handleStopDragging}
        css={[
          tw`flex items-center justify-center w-full h-full border-2 border-white border-dashed rounded-xl`,
          !conceptHash && tw`ml-[var(--sidebar-width)]`,
          conceptHash && tw`mt-16`,
        ]}
      >
        <h2 tw="pointer-events-none text-2xl text-white">
          Drop files to upload
        </h2>
      </div>
    </div>
  );
};

export default FullPageDropZone;
