/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { Link, LinkProps, useNavigate, useParams } from "react-router-dom";

import { StarOutlineRounded, StarRounded } from "@mui/icons-material";
import { Checkbox, Fade } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { Concept } from "@models/Concept";
import { CldImage } from "@services/cloudinary";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { conceptsKeyFactory, useUpdateConceptMutation } from "../data";

const T_STAGGER_DELAY = 30;

const getPreviewImage = (concept: Concept) => {
  const file = concept.cloudinaryFiles.find(
    (file) => file.resourceType === "image"
  );
  if (!file) return "";
  return `${file.cloudinaryId}.jpg`;
};

export type ConceptCardProps = Partial<LinkProps> & {
  concept: Concept;
  i?: number;
};

type ConceptCardPropsWithLoaded = ConceptCardProps & {
  loaded: boolean;
  setLoaded: Dispatch<SetStateAction<boolean>>;
};
export const Card = tw.div`relative bg-white rounded-lg overflow-hidden text-neutral-600 
  focus:shadow-xl group-hover:shadow-xl hover:shadow-xl transition-all duration-300 
  border-2 border-transparent hover:border-neutral-300
  cursor-pointer`;

const CardTitle = tw.h6`text-center text-neutral-500 [text-wrap: pretty] leading-tight text-sm line-clamp-2`;

const AuthorGroupTag = ({ concept }) => {
  const supplierId = useSelector(
    (state: any) => state.currentUser.supplier?.id
  );
  const orgName = useSelector(
    (state: any) => state.currentUser.organization.name
  );
  const authorGroupName =
    supplierId === concept.ownerSupplier?.id
      ? undefined
      : concept.ownerSupplier?.name ?? orgName;

  if (!authorGroupName) return null;
  return <span tw="text-neutral-400"> — {authorGroupName}</span>;
};

const CardWrapper = ({
  concept,
  i,
  loaded,
  setLoaded: _,
  ...props
}: ConceptCardPropsWithLoaded & PropsWithChildren) => {
  const queryClient = useQueryClient();
  const { boardId } = useParams();
  const navigate = useNavigate();
  return (
    <Fade
      in={loaded}
      style={{ transitionDelay: `${(i ?? 0) * T_STAGGER_DELAY}ms` }}
      timeout={500}
    >
      <Link
        to={{ hash: `#${concept.id}`, search: window.location.search }}
        replace
        className="group"
        {...props}
        onClick={(e) => {
          if (concept.suppliers.length > 0 && !concept.parentConceptId) {
            // Open Board view
            e.preventDefault();
            queryClient.setQueryData<Concept>(
              conceptsKeyFactory.detail(concept.id).queryKey,
              concept
            );
            navigate(`/create/boards/${boardId}/${concept.id}`);
          }
        }}
      />
    </Fade>
  );
};

const StarConcept = ({ concept }: { concept: Concept }) => {
  const { isFavorite } = concept;
  const updateConceptMutation = useUpdateConceptMutation();
  const isAdmin = useRoleIs()(permissions.admin);

  const handleChange = (val) => {
    updateConceptMutation.mutate({
      id: concept.id,
      cloudinaryFileIds: concept.cloudinaryFiles.map((cf) => cf.id),
      supplierIds: concept.suppliers.map((s) => s.id),
      conceptCategoryIds: concept.conceptCategories.map((c) => c.id),
      isFavorite: !val,
    });
  };

  if (!isAdmin) return null;

  return (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      checked={!!isFavorite}
      onChange={(e) => handleChange(!e.target.checked)}
      css={[
        tw`absolute top-0.5 right-0.5 p-2 opacity-0 group-hover:opacity-100 transition-opacity`,
        isFavorite && tw`opacity-100`,
      ]}
      icon={<StarOutlineRounded />}
      checkedIcon={<StarRounded tw="text-primary-600" />}
    />
  );
};

const ConceptImageCard = ({
  concept,
  i,
  ...props
}: ConceptCardPropsWithLoaded) => (
  <CardWrapper concept={concept} i={i} {...props}>
    <Card>
      <StarConcept concept={concept} />
      <CldImage
        publicId={getPreviewImage(concept)}
        tw="max-h-[500px] mx-auto pointer-events-none"
        size="medium"
        onLoad={() => props.setLoaded(true)}
      />
    </Card>
    <CardTitle tw="p-2 pb-0 -mb-1">
      {concept.title}
      <AuthorGroupTag concept={concept} />
    </CardTitle>
  </CardWrapper>
);

const ConceptTextCard = ({
  concept,
  i,
  ...props
}: ConceptCardPropsWithLoaded) => {
  const hasContent = !!concept.description || !!concept.title;
  return (
    <CardWrapper concept={concept} i={i} {...props}>
      <Card tw="py-4 px-6">
        {hasContent && (
          <>
            <StarConcept concept={concept} />
            <p tw="text-neutral-600 line-clamp-4 [text-wrap: balance] mb-3 empty:mb-0">
              {concept.description}
            </p>
            <CardTitle>
              {concept.title}
              <AuthorGroupTag concept={concept} />
            </CardTitle>
          </>
        )}
        {!hasContent && (
          <p tw="text-neutral-400 text-center text-sm">No Content Yet</p>
        )}
      </Card>
    </CardWrapper>
  );
};

const ConceptCard = ({ concept, ...props }: ConceptCardProps) => {
  const images = concept.cloudinaryFiles.filter(
    (file) => file.resourceType === "image"
  );
  const [loaded, setLoaded] = useState(images.length === 0);
  return images.length === 0 ? (
    <ConceptTextCard
      key={concept.id}
      concept={concept}
      loaded={loaded}
      setLoaded={setLoaded}
      {...props}
    />
  ) : (
    <ConceptImageCard
      key={concept.id}
      concept={concept}
      loaded={loaded}
      setLoaded={setLoaded}
      {...props}
    />
  );
};

export default ConceptCard;
