import { useParams } from "react-router-dom";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useConceptBoardContext } from "@features/conceptBoards";
import { Concept } from "@models/Concept";
import client, { RequestParams } from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

export const conceptsKeyFactory = createQueryKeys("concepts", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Concept>(`concepts/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<Concept[]>("concepts", {
          params: { ...params, skipPagination: true },
        })
        .then((res) => res.data),
  }),
});

export const useConceptQuery = (
  id: string | null | undefined,
  conf?: QueryOptions<Concept>
) => {
  return useQuery({
    ...conceptsKeyFactory.detail(id!),
    enabled: !!id,
    ...conf,
  });
};

export const useConceptsListQuery = (
  params: RequestParams,
  options?: QueryOptions<Concept[]>
) => {
  return useQuery({
    ...conceptsKeyFactory.list(params),
    structuralSharing: true,
    ...options,
  });
};

export const useCurrentBoardConceptsQuery = () => {
  const { boardId: id } = useParams();
  return useConceptsListQuery(
    {
      filter: {
        boardId: id!,
      },
      sort: "-inserted-at",
    },
    { enabled: !!id }
  );
};

type CreateConceptPayload = {
  description?: string;
  cloudinaryFileIds?: string[];
  parentConceptId?: string;
};

export const useCreateConceptMutation = () => {
  const queryClient = useQueryClient();
  const { id: conceptBoardId } = useConceptBoardContext();
  return useMutation({
    mutationFn: ({ cloudinaryFileIds, ...data }: CreateConceptPayload) =>
      client
        .post<Concept>("concepts", {
          ...data,
          status: "draft",
          conceptBoard: { __type: "concept-board", id: conceptBoardId },
          cloudinaryFilesIds: cloudinaryFileIds?.map(String),
          relationshipNames: ["conceptBoard"],
        })
        .then((res) => res.data),
    onSuccess: (item) => {
      queryClient.invalidateQueries({
        queryKey: conceptsKeyFactory.list._def,
      });
      queryClient.setQueryData<Concept>(
        conceptsKeyFactory.detail(item.id).queryKey,
        item
      );
    },
  });
};

type UpdateConceptPayload = {
  id: string;
  title?: string;
  description?: string;
  isFavorite?: boolean;
  cloudinaryFileIds: string[];
  supplierIds: string[];
  conceptCategoryIds: string[];
};

export const useUpdateConceptMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      cloudinaryFileIds,
      supplierIds,
      conceptCategoryIds,
      ...data
    }: UpdateConceptPayload) =>
      client
        .update<Concept>(`concepts/${id}`, {
          ...data,
          cloudinaryFilesIds: cloudinaryFileIds,
          conceptCategoriesIds: conceptCategoryIds,
          suppliersIds: supplierIds,
        })
        .then((res) => res.data),
    onSuccess: (item) => {
      queryClient.invalidateQueries({
        queryKey: conceptsKeyFactory.list._def,
      });
      queryClient.setQueryData<Concept>(
        conceptsKeyFactory.detail(item.id).queryKey,
        item
      );
    },
  });
};

export const useDeleteConceptMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => client.delete(`concepts/${id}`),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({
        queryKey: conceptsKeyFactory.list._def,
      });
      return queryClient.setQueryData<Concept>(
        conceptsKeyFactory.detail(id).queryKey,
        undefined
      );
    },
  });
};
