/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useWatch } from "react-hook-form";

import { StarOutlineRounded, StarRounded } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useFilterContext } from "../filterContext";
import { Bordered } from "./StyledComponents";

const StarredChip = () => {
  const { setValue, control, setFilterValues, getValues } = useFilterContext();
  const favorite = useWatch({ control, name: "favorite" }) ?? false;

  const handleClick = () => {
    setValue("favorite", !favorite);
    setFilterValues(getValues());
  };

  return (
    <Bordered tw="h-10 overflow-hidden">
      <Button
        disableElevation
        onClick={handleClick}
        tw="bg-white! py-0 h-full normal-case"
        startIcon={
          favorite ? (
            <StarRounded tw="text-primary-600 ml-1 -mr-1 text-2xl!" />
          ) : (
            <StarOutlineRounded tw="text-primary-600 ml-1 -mr-1 text-2xl!" />
          )
        }
      >
        Favorites
      </Button>
    </Bordered>
  );
};

const filterConceptFavorites = {
  name: "conceptFavorites",
  formControls: ["favorite"],
  menuText: "Favorite",
  Chip: StarredChip,
  alwaysShow: true,
};

export default filterConceptFavorites;
