/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Masonry } from "@mui/lab";
import { Collapse, IconButton } from "@mui/material";

import { Contained } from "@components/StyledComponents";
import {
  ConceptModal,
  useCurrentFilteredBoardConcepts,
} from "@features/concepts";
import { Filters } from "@features/filters";
import { FaIcon } from "@features/ui";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import ConceptCard from "../concepts/components/ConceptCard";
import NewConceptCard from "../concepts/components/NewConceptCard";
import FullPageDropZone from "./components/FullPageDropZone";
import WorkspaceOverview from "./components/WorkspaceOverview";
import WorkspaceTitle from "./components/WorkspaceTitle";

const MIN_COL_WIDTH = 400;

const ConceptBoardWorkspace = () => {
  const { conceptId } = useParams();
  const [showOverview, setShowOverview] = useState(true);
  const [columns, setColumns] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const concepts = useCurrentFilteredBoardConcepts();
  const isAdmin = useRoleIs()(permissions.admin);

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      setColumns(Math.ceil(containerRef.current.clientWidth / MIN_COL_WIDTH));
    }
  }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const filterSlots = useMemo(
    () => (isAdmin ? ["conceptFavorites", "conceptSuppliers"] : []),
    [isAdmin]
  );

  return (
    <>
      <FullPageDropZone />
      <ConceptModal />
      <Contained tw="max-w-none">
        <div tw="grid grid-cols-[1fr_auto]">
          <div ref={containerRef} tw="space-y-4">
            <WorkspaceTitle />
            <div>
              <Filters slots={filterSlots} />
            </div>
            <Masonry columns={columns} spacing={2}>
              {(isAdmin || conceptId) && <NewConceptCard />}
              {concepts.map((concept, i) => (
                <ConceptCard key={concept.id} concept={concept} i={i} />
              ))}
            </Masonry>
          </div>
          <div tw="pl-4" className="group">
            <div tw="rounded-xl bg-white p-4 self-start min-w-0 relative">
              <IconButton
                css={[
                  tw`absolute left-0 z-10 transition-opacity -translate-x-1/2 top-2 group-hover:opacity-100`,
                  showOverview && tw`opacity-0`,
                ]}
                onClick={() => setShowOverview(!showOverview)}
              >
                <div tw="bg-primary-600 w-5 h-5 shadow-md text-white rounded-full flex items-center justify-center">
                  <FaIcon
                    icon={showOverview ? "angle-right" : "angle-left"}
                    tw="text-base"
                    variant="solid"
                  />
                </div>
              </IconButton>
              <Collapse
                in={showOverview}
                orientation="horizontal"
                onExited={handleResize}
                onEntered={handleResize}
              >
                <div tw="px-2 w-[384px]">
                  <WorkspaceOverview />
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </Contained>
    </>
  );
};

export default ConceptBoardWorkspace;
