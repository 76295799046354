/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { InputBase, TextareaAutosize } from "@mui/material";

import _ from "lodash";

import { useUpdateConceptMutation } from "@features/concepts";
import { Concept } from "@models/Concept";

import { useConceptContext } from "../ConceptContext";
import ConceptCategoriesSelector from "./ConceptCategoriesSelector";
import ConceptFiles from "./ConceptFiles";
import ConceptStatusSelector from "./ConceptStatusSelector";
import ConceptSuppliersSelector from "./ConceptSuppliersSelector";
import ConceptsFavoriteToggle from "./ConceptsFavoriteToggle";

const formValuesFromConcept = (concept: Concept) => ({
  title: concept.title ?? "",
  description: concept.description ?? "",
  status: concept.status ?? "",
  supplierIds: concept.suppliers.map((s) => s.id),
  conceptCategoryIds: concept.conceptCategories.map((c) => c.id),
});

type ConceptFormData = ReturnType<typeof formValuesFromConcept>;
type FormField = keyof ConceptFormData;

const ConceptForm = ({ setInitialFilePreview }) => {
  const concept = useConceptContext();
  const updateConceptMutation = useUpdateConceptMutation();
  const {
    register: reg,
    reset,
    control,
    handleSubmit,
    getFieldState,
    getValues,
  } = useForm<ConceptFormData>({
    defaultValues: formValuesFromConcept(concept),
  });

  const handleSave = (field: FormField, value: any) => {
    if (!getFieldState(field).isDirty) return;

    updateConceptMutation.mutate({
      id: concept.id,
      cloudinaryFileIds: concept.cloudinaryFiles.map((cf) => cf.id),
      supplierIds: concept.suppliers.map((s) => s.id),
      conceptCategoryIds: concept.conceptCategories.map((c) => c.id),
      [field]: value,
    });
  };

  const register = (name: FormField) => ({
    ...reg(name),
    onBlur: handleSubmit((d) => handleSave(name, _.get(d, name))),
  });

  useEffect(() => {
    reset(formValuesFromConcept(concept));
  }, [concept, reset]);
  return (
    <form
      onKeyDown={(e) => e.stopPropagation()}
      onSubmit={(e) => e.preventDefault()}
    >
      <InputBase
        placeholder="Give your concept a title..."
        fullWidth
        tw="mb-1 text-3xl font-light px-3"
        {...register("title")}
      />
      <div tw="flex flex-wrap gap-x-2 gap-y-1 px-3">
        <ConceptStatusSelector control={control} {...register("status")} />
        <ConceptsFavoriteToggle />
        <ConceptCategoriesSelector
          control={control}
          canEdit
          onChange={() =>
            handleSave("conceptCategoryIds", getValues("conceptCategoryIds"))
          }
        />
      </div>
      <div tw="mx-3 mt-4 py-2 px-4 mb-6 bg-white rounded-lg rounded-t-lg border border-neutral-200  ">
        <TextareaAutosize
          placeholder="Add a description..."
          tw="px-0 w-full text-neutral-900 border-0 focus:ring-0 focus:outline-none"
          minRows={2}
          {...register("description")}
        />
      </div>
      <div tw="px-3 mb-6">
        <ConceptFiles setInitialFilePreview={setInitialFilePreview} />
      </div>
      <div tw="flex justify-between items-center mb-3">
        <h2 tw="ml-3 text-lg font-medium text-neutral-700">Suppliers</h2>
        {!concept.ownerSupplier && (
          <ConceptSuppliersSelector
            control={control}
            onChange={() => handleSave("supplierIds", getValues("supplierIds"))}
          />
        )}
      </div>
    </form>
  );
};

export default ConceptForm;
