import { Avatar, AvatarProps, Tooltip } from "@mui/material";

import { stringAvatar } from "./helpers";

const SupplierAvatar = ({ name, ...props }: AvatarProps & { name: string }) => {
  return (
    <Tooltip title={name} placement="bottom" arrow>
      <Avatar tw="w-10 h-10" {...stringAvatar(name)} alt={name} {...props} />
    </Tooltip>
  );
};

export default SupplierAvatar;
