/** @jsxImportSource @emotion/react */
import "twin.macro";

import Carousel from "@components/Carousel";
import { CloudinaryFile } from "@models/CloudinaryFile";

import CloudinaryFileSlide from "./CloudinaryFileSlide";

const CloudinaryFileCarousel = ({
  files,
  startIndex,
}: {
  files: CloudinaryFile[];
  startIndex?: number;
}) => {
  return (
    <Carousel startIndex={startIndex}>
      {files.map((file) => (
        <CloudinaryFileSlide file={file} key={file.cloudinaryId} />
      ))}
    </Carousel>
  );
};

export default CloudinaryFileCarousel;
