/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import { ControlledSelectInput } from "@utils/forms";
import { ControlledSelectInputProps } from "@utils/forms/ControlledInputs";

const statusOptions = [
  { id: "draft", name: "Draft" },
  { id: "work-in-progress", name: "Work in Progress" },
  { id: "complete", name: "Complete" },
];

const ConceptStatusSelector = (
  props: Omit<ControlledSelectInputProps, "options">
) => {
  return (
    <ControlledSelectInput
      options={statusOptions}
      fullWidth={false}
      css={{
        ".MuiSelect-select.MuiInputBase-input": tw`pl-3 text-sm text-purple-800 bg-purple-100 border-0`,
        ".MuiSelect-icon": tw`text-purple-800 `,
      }}
      {...props}
    />
  );
};

export default ConceptStatusSelector;
