/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";

import { StarOutlineRounded, StarRounded } from "@mui/icons-material";

import { RectChip } from "@features/ui";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useConceptContext } from "../ConceptContext";
import { useUpdateConceptMutation } from "../data";

const ConceptsFavoriteToggle = () => {
  const concept = useConceptContext();
  const isAdmin = useRoleIs()(permissions.admin);
  const { isFavorite } = concept;
  const [checked, setChecked] = useState(isFavorite);
  const updateConceptMutation = useUpdateConceptMutation();

  const handleChange = (val) => {
    setChecked(val);
    updateConceptMutation.mutate({
      id: concept.id,
      cloudinaryFileIds: concept.cloudinaryFiles.map((cf) => cf.id),
      supplierIds: concept.suppliers.map((s) => s.id),
      conceptCategoryIds: concept.conceptCategories.map((c) => c.id),
      isFavorite: !checked,
    });
  };

  useEffect(() => {
    setChecked(concept.isFavorite);
  }, [concept.isFavorite]);

  if (!isAdmin) return null;

  return (
    <RectChip onClick={() => handleChange(!checked)} tw="cursor-pointer">
      Favorite
      {!checked && (
        <StarOutlineRounded tw="text-primary-800 text-lg ml-1 -mr-1" />
      )}
      {checked && <StarRounded tw="text-primary-800 text-lg ml-1 -mr-1" />}
    </RectChip>
  );
};

export default ConceptsFavoriteToggle;
