import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ConceptCategory } from "@models/ConceptBoard";
import client, { RequestParams } from "@services/api";

export const conceptCategoriesKeyFactory = createQueryKeys(
  "concept-categories",
  {
    list: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client
          .get<ConceptCategory[]>(`concept-categories`, { params })
          .then((res) => res.data),
    }),
  }
);

export const useConceptCategoriesQuery = (params: RequestParams) => {
  return useQuery({
    ...conceptCategoriesKeyFactory.list(params),
    staleTime: 5 * 60 * 1000,
  });
};

export const useCreateConceptCategoryMutation = () => {
  const queryClient = useQueryClient();
  const organizationId = useSelector(
    (state: any) => state.currentUser.organization.id
  );

  return useMutation({
    mutationFn: (name: string) =>
      client
        .post<ConceptCategory>("concept-categories", {
          name,
          organization: { id: organizationId, type: "organization" },
          relationshipNames: ["organization"],
        })
        .then((res) => res.data),
    onSuccess: (category) => {
      queryClient.invalidateQueries({
        queryKey: conceptCategoriesKeyFactory.list._def,
        type: "inactive",
      });
      return queryClient.setQueriesData<ConceptCategory[]>(
        { queryKey: conceptCategoriesKeyFactory.list._def, type: "active" },
        (categories) => categories && [...categories, category]
      );
    },
  });
};

export const useUpdateConceptCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: ConceptCategory) =>
      client
        .update<ConceptCategory>(`concept-categories/${id}`, data)
        .then((res) => res.data),
    onSuccess: (category, { id }) => {
      queryClient.invalidateQueries({
        queryKey: conceptCategoriesKeyFactory.list._def,
        type: "inactive",
      });
      return queryClient.setQueriesData<ConceptCategory[]>(
        { queryKey: conceptCategoriesKeyFactory.list._def, type: "active" },
        (categories) =>
          categories && categories.map((c) => (c.id === id ? category : c))
      );
    },
  });
};
