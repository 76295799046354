/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React from "react";

import { Person, Toll } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useSetHash } from "@services/reactRouterDom";

import { useConceptContext } from "../ConceptContext";

const ButtonChip = tw(
  Button
)`text-xs font-normal normal-case rounded-full py-0.5 px-2 text-neutral-600`;
const ConceptInfo = () => {
  const { parentConceptId, ownerName } = useConceptContext();
  const setHash = useSetHash();

  return (
    <div tw="flex gap-3 flex-wrap ml-2 mb-2 items-center">
      <ButtonChip size="small" startIcon={<Person />}>
        {ownerName}
      </ButtonChip>
      {parentConceptId && (
        <ButtonChip
          size="small"
          startIcon={<Toll />}
          onClick={() => setHash(String(parentConceptId))}
          tw="text-primary-700 bg-primary-100!"
        >
          Parent Concept
        </ButtonChip>
      )}
    </div>
  );
};

export default ConceptInfo;
