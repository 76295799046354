/** @jsxImportSource @emotion/react */
import "twin.macro";

import {
  ConceptBoardProvider,
  ConceptBoardWorkspace,
} from "@features/conceptBoards";

const ConceptBoard = () => {
  return (
    <ConceptBoardProvider>
      <ConceptBoardWorkspace />
    </ConceptBoardProvider>
  );
};

export default ConceptBoard;
