/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { CloudinaryFileCarouselModal } from "@features/cloudinaryFiles";
import useConfirm from "@features/confirm";
import { useSetHash } from "@services/reactRouterDom";

import { useConceptContext } from "../ConceptContext";
import { useDeleteConceptMutation } from "../data";
import ConceptForm from "./ConceptForm";
import ConceptInfo from "./ConceptInfo";
import ConceptReadOnlyInfo from "./ConceptReadOnlyInfo";
import ConceptSupplierDiscussion from "./discussions/ConceptSupplierDiscussion";

const ConceptOverview = () => {
  const confirm = useConfirm();
  const concept = useConceptContext();
  const supplierId = useSelector(
    (state: any) => state.currentUser.supplier?.id
  );
  const canEdit = supplierId === concept.ownerSupplier?.id;

  const deleteConceptMutation = useDeleteConceptMutation();
  const setHash = useSetHash();

  const [initialFilePreview, setInitialFilePreview] = useState<null | number>(
    null
  );

  const handleDelete = async () => {
    if (!(await confirm("Are you sure you want to delete this concept?")))
      return;
    deleteConceptMutation.mutate(concept.id, {
      onSuccess: () => {
        setHash(null);
      },
    });
  };
  return (
    <div>
      <ConceptInfo />
      {canEdit ? (
        <ConceptForm setInitialFilePreview={setInitialFilePreview} />
      ) : (
        <ConceptReadOnlyInfo setInitialFilePreview={setInitialFilePreview} />
      )}
      <div tw="mt-4 space-y-2">
        {supplierId && (
          <ConceptSupplierDiscussion
            supplier={concept.suppliers.find((s) => s.id === supplierId)}
          />
        )}
        {!supplierId &&
          concept.suppliers.map((supplier) => (
            <ConceptSupplierDiscussion key={supplier.id} supplier={supplier} />
          ))}
      </div>
      {canEdit && (
        <button
          tw="mt-8 float-right text-sm block text-neutral-600 hover:text-red-700 hover:underline"
          onClick={handleDelete}
        >
          Delete Concept
        </button>
      )}
      <CloudinaryFileCarouselModal
        files={concept.cloudinaryFiles}
        open={initialFilePreview !== null}
        handleClose={() => setInitialFilePreview(null)}
        startIndex={initialFilePreview || 0}
      />
    </div>
  );
};

export default ConceptOverview;
