/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import ClickAwayListener from "react-advanced-click-away";
import { Control, Controller, useWatch } from "react-hook-form";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, InputBase, Popper } from "@mui/material";

import _ from "lodash";

import { StyledButton } from "@components/StyledComponents";
import { useConceptBoardContext } from "@features/conceptBoards";
import { StyledPopperComponent } from "@features/filters";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ConceptSupplierAutocomplete = ({ control, handleChange }) => {
  const initialValues = useWatch({ name: "supplierIds", control });
  const { suppliers } = useConceptBoardContext();
  const [value, setValue] = React.useState<string[]>(initialValues);
  return (
    <ClickAwayListener onClickAway={() => handleChange(value)}>
      <div tw="bg-white rounded-lg shadow-lg">
        <Autocomplete
          value={value as any[]}
          open
          size="small"
          disableCloseOnSelect
          onChange={async (event, newValue: any[]) => {
            setValue(newValue.map((v) => v.id ?? v));
          }}
          filterOptions={(options, params) => {
            const inputValue = params.inputValue.trim();

            const filtered = _(options)
              .filter((option) =>
                inputValue !== ""
                  ? option.name.toLowerCase().includes(inputValue.toLowerCase())
                  : true
              )
              .sortBy(
                (opt) => (initialValues.includes(opt.id) ? 0 : 1),
                (option) => option.name.toLowerCase()
              )
              .value();

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={suppliers}
          getOptionLabel={(option: any) => {
            return suppliers.find((c) => c.id === option)?.name ?? "";
          }}
          renderOption={(props: any, option, { selected }) => (
            <li
              {...props}
              tw="py-0! px-0!"
              key={option.id} // override onClick to create the new value
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              <span tw="whitespace-nowrap pr-3">{option.name}</span>
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.id === value}
          disableClearable
          multiple
          renderInput={(params) => {
            return (
              <InputBase
                tw="p-3 z-[1450]"
                autoFocus
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder="Search Suppliers"
              />
            );
          }}
          PopperComponent={StyledPopperComponent}
        />
      </div>
    </ClickAwayListener>
  );
};

const ConceptSuppliersSelector = ({
  control,
  onChange,
}: {
  control: Control<any>;
  onChange: () => void;
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (menuAnchorEl) return;
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <Controller
      control={control}
      name="supplierIds"
      render={({ field }) => {
        return (
          <>
            <StyledButton cta onClick={handleOpenMenu}>
              Add Suppliers
            </StyledButton>

            {menuAnchorEl && (
              <Popper
                open
                anchorEl={menuAnchorEl}
                placement="bottom-start"
                tw="pt-1"
              >
                <ConceptSupplierAutocomplete
                  control={control}
                  handleChange={(newValue) => {
                    field.onChange(newValue);
                    onChange();
                    handleCloseMenu();
                  }}
                />
              </Popper>
            )}
          </>
        );
      }}
    />
  );
};

export default ConceptSuppliersSelector;
