function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + (hash << 3);
  }

  let h = hash % 360;
  let s = 50 + (hash % 10); // Saturation from 50% to 60%
  let b = 40 + (hash % 20); // Brightness from 40% to 60%

  return `hsl(${h}, ${s}%, ${b}%)`;
}

export function stringAvatar(name: string) {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}
