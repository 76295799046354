/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import {
  FixedHeightScrollLastChild,
  PageTitle,
  StyledButton,
} from "@components/StyledComponents";
import {
  conceptBoardsKeyFactory,
  useCreateConceptBoardMutation,
  usePaginatedConceptBoardsQuery,
} from "@features/conceptBoards";
import { ConceptBoard } from "@models/ConceptBoard";
import { useMutateError } from "@services/api";
import DocTitle from "@utility/DocTitle";

const CreateBoards = () => {
  const navigate = useNavigate();
  const setError = useMutateError();
  const queryClient = useQueryClient();
  const createBoardMutation = useCreateConceptBoardMutation();

  const handleCreateBoard = async () => {
    createBoardMutation.mutate(undefined, {
      onSuccess: (board) => {
        navigate(`/create/boards/${board.id}/edit`);
      },
      onError: (err) => {
        setError(err, "Create Board");
      },
    });
  };

  const { data, isLoading } = usePaginatedConceptBoardsQuery({});

  return (
    <FixedHeightScrollLastChild>
      <DocTitle title={"Budgets"} />
      <div tw="flex justify-between items-center">
        <PageTitle>Concept Boards</PageTitle>
        <StyledButton cta onClick={handleCreateBoard}>
          New Board
        </StyledButton>
      </div>
      {isLoading && <CircularProgress />}
      {data?.map((board) => (
        <div key={board.id}>
          <h2>
            <Link
              to={`/create/boards/${board.id}`}
              onClick={() =>
                queryClient.setQueryData<ConceptBoard>(
                  conceptBoardsKeyFactory.detail(board.id).queryKey,
                  board
                )
              }
            >
              {board.title}
            </Link>
          </h2>
        </div>
      ))}
    </FixedHeightScrollLastChild>
  );
};

export default CreateBoards;
