/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";
import { Toaster } from "react-hot-toast";

const ConfiguredToaster = () => {
  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: tw`bg-white rounded-lg shadow-lg`,

        // Default options for specific types
        success: {
          duration: 3000,
          style: {},
        },
      }}
    />
  );
};

export default ConfiguredToaster;
