/** @jsxImportSource @emotion/react */
import "twin.macro";

import { MouseEventHandler } from "react";

import { DeleteRounded, DownloadRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { CloudinaryFile } from "@models/CloudinaryFile";
import { CldImage } from "@services/cloudinary";

import getFileUrl from "./getCloudinaryFileUrl";

type CloudinaryFileProps = {
  file: CloudinaryFile;
  onDelete?: (id: string) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
const FileCard = ({ file, onDelete, onClick }: CloudinaryFileProps) => {
  const downloadUrl = getFileUrl(file);

  return (
    <div
      tw="grid grid-rows-[1fr_auto]
      w-32 h-32 overflow-hidden rounded 
      border border-neutral-400 drop-shadow-sm 
      hover:drop-shadow-xl bg-white transition-all select-none"
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <div tw="flex items-center justify-center overflow-hidden">
        {file.resourceType === "image" && (
          <CldImage
            publicId={`${file.cloudinaryId}.jpg`}
            size="medium"
            tw="w-full h-full object-cover object-center pointer-events-none"
          />
        )}
        {file.resourceType !== "image" && (
          <div tw="p-3 break-words text-sm text-neutral-500">
            {file.fileName}.{file.format}
          </div>
        )}
      </div>
      <div tw="flex items-center justify-between gap-1 bg-white">
        <div tw="flex gap-1">
          {onDelete && (
            <Tooltip title="Delete">
              <IconButton
                size="small"
                edge="end"
                onClick={() => onDelete(file.id)}
              >
                <DeleteRounded tw="text-xl" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Download">
            <IconButton size="small" href={downloadUrl}>
              <DownloadRounded tw="text-xl" />
            </IconButton>
          </Tooltip>
        </div>

        <div tw="bg-neutral-900/60 text-white font-medium text-xs px-1 mr-1 rounded uppercase">
          {file.format}
        </div>
      </div>
    </div>
  );
};

export default FileCard;
