/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import _ from "lodash";

import { FaIcon } from "@features/ui";
import { StrippedSupplier } from "@models/Supplier";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useConceptContext } from "../../ConceptContext";
import { useDiscussionQuery, useDiscussionsListQuery } from "../../data";
import DiscussionForm from "./DiscussionForm";
import Message from "./Message";

const ConceptSupplierDiscussion = ({
  supplier,
}: {
  supplier: StrippedSupplier;
}) => {
  const roleIs = useRoleIs();
  const isSupplier = roleIs(permissions.supplier);
  const orgName = useSelector(
    (state: any) => state.currentUser.organization.name
  );
  const { id: conceptId } = useConceptContext();

  const { data: discussions = [] } = useDiscussionsListQuery(conceptId);
  const supplierDiscussion = discussions.find(
    (d) => d.supplierId === +supplier.id
  );
  const { data: discussion } = useDiscussionQuery(supplierDiscussion?.id, {
    initialData: supplierDiscussion as any,
  });

  return (
    <div>
      <Accordion
        defaultExpanded
        elevation={0}
        tw="rounded-lg border border-neutral-200"
      >
        <AccordionSummary expandIcon={<FaIcon icon="angle-down" />}>
          {/* <h3 tw="font-medium"> */}
          Discussion with {isSupplier ? orgName : supplier.name}
          {discussion && ` (${discussion.messages.length})`}
          {/* </h3> */}
        </AccordionSummary>
        <AccordionDetails>
          <DiscussionForm supplier={supplier} discussionId={discussion?.id} />
          {discussion && (
            <div tw="flex flex-col gap-2 max-h-72 overflow-y-auto divide-y py-2">
              {_.reverse([...discussion.messages]).map((msg) => {
                return (
                  <Message
                    key={msg.id}
                    message={msg}
                    discussionId={discussion.id}
                  />
                );
              })}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ConceptSupplierDiscussion;
