/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useParams } from "react-router-dom";

import { Skeleton } from "@mui/material";

import { useConceptQuery } from "@features/concepts";
import { FaIcon, Title } from "@features/ui";

import { useConceptBoardContext } from "../ConceptBoardContext";

const WorkspaceTitle = () => {
  const { boardId, conceptId } = useParams();
  const { title: boardTitle } = useConceptBoardContext();
  const { data } = useConceptQuery(conceptId);

  if (!conceptId) return <Title backUrl={`/create/boards`}>{boardTitle}</Title>;
  return (
    <Title backUrl={`/create/boards/${boardId}`}>
      <Link
        to={`/create/boards/${boardId}`}
        state={{ preventLastLocation: true }}
        tw="hover:underline"
      >
        {boardTitle}
      </Link>
      <FaIcon icon="arrow-right" tw="mx-3 text-neutral-300" />
      <span>
        {data ? (
          data.title || "Untitled Concept"
        ) : (
          <Skeleton tw="inline-block" variant="text" width={120} />
        )}
      </span>
    </Title>
  );
};

export default WorkspaceTitle;
