/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { TextField } from "@mui/material";

import { format } from "date-fns";

import { StyledButton } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { Message as TMessage } from "@models/Discussion";
import { utcToLocalDate } from "@utility/utilityFunctions";

import { useDeleteMessageMutation, useUpdateMessageMutation } from "../../data";

const EditMessageForm = ({
  originalMessage,
  handleComplete,
  discussionId,
}: {
  discussionId: string;
  originalMessage: TMessage;
  handleComplete: () => void;
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: { message: originalMessage.message },
  });

  const updateMessageMutation = useUpdateMessageMutation();

  const handleUpdate = async ({ message }) => {
    await updateMessageMutation.mutateAsync({
      id: originalMessage.id,
      message,
      discussionId,
    });
    handleComplete();
  };

  return (
    <div tw="flex flex-col gap-2  w-full">
      <TextField
        tw="w-full"
        autoFocus
        multiline
        {...register("message")}
        fullWidth
        onKeyDown={(e) => e.stopPropagation()}
      />
      <div tw="flex gap-2">
        <StyledButton cta size="small" onClick={handleSubmit(handleUpdate)}>
          Update
        </StyledButton>
        <StyledButton size="small" onClick={() => handleComplete()}>
          Cancel
        </StyledButton>
      </div>
    </div>
  );
};

const Message = ({
  message,
  discussionId,
}: {
  message: TMessage;
  discussionId: string;
}) => {
  const [editing, setEditing] = React.useState(false);
  const confirm = useConfirm();
  const userEmail: string = useSelector(
    (state: any) => state.currentUser.email
  );
  const mine = message.userEmail === userEmail;

  const deleteMessageMutation = useDeleteMessageMutation();

  const handleDeleteMessage = async (id) => {
    if (!(await confirm("Are you sure you want to delete this message?")))
      return;
    deleteMessageMutation.mutate({ id, discussionId });
  };

  return (
    <div tw="py-2 px-4">
      <div tw="flex gap-2 items-baseline">
        <h5 tw="font-medium text-neutral-600">{message.userName}</h5>
        <span tw="text-sm text-neutral-500">
          {format(utcToLocalDate(message.insertedAt), "PPp")}
        </span>
        {message.insertedAt !== message.updatedAt && (
          <span tw="text-sm text-neutral-500">
            Edited {format(utcToLocalDate(message.updatedAt), "PPp")}
          </span>
        )}
      </div>
      {editing ? (
        <EditMessageForm
          originalMessage={message}
          handleComplete={() => setEditing(false)}
          discussionId={discussionId}
        />
      ) : (
        <>
          <p tw="whitespace-pre-wrap">{message.message}</p>
          {mine && (
            <div tw="flex gap-4 text-sm mt-1 text-neutral-500">
              <button onClick={() => setEditing(true)}>Edit</button>
              <button onClick={() => handleDeleteMessage(message.id)}>
                Delete
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Message;
