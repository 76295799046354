/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Button,
  CircularProgress,
  LinearProgress,
  Tab,
  Tabs,
} from "@mui/material";

import clsx from "clsx";

/**
 * @deprecated use Button / LoadingButton instead
 */

export const StyledButton = ({
  outlined = false,
  cta = false,
  danger = false,
  loading = false,
  disabled = false,
  ...props
}) => (
  <Button
    {...(props.to && { component: Link })}
    css={[
      tw`relative px-4 no-underline transition-all duration-200 !bg-white shadow-sm text-neutral-700`,
      tw`hover:(shadow-md !bg-white)`,
      disabled && tw`shadow-none text-neutral-500`,

      cta &&
        tw`text-white bg-gradient-to-r! from-neutral-800 to-neutral-700 hover:(bg-neutral-500)!`,
      cta &&
        (disabled || loading) &&
        tw`from-neutral-400! to-neutral-400! text-neutral-100!`,

      outlined &&
        tw`border border-solid shadow-none border-neutral-300 hover:border-neutral-500`,
      outlined && disabled && tw`border-neutral-200 bg-transparent!`,

      danger &&
        tw`border border-solid border-transparent shadow-none 
           hover:(text-red-600 border-red-400)`,
    ]}
    disabled={loading || disabled}
    {...props}
    {...(loading && { endIcon: <CircularProgress size={16} /> })}
  />
);

export const Contained = tw.div`px-2 md:px-4 mx-auto`;

export const OpaqueCard = tw.div`rounded-lg bg-white shadow-sm p-6 overflow-hidden`;
export const RecessCard = tw.div`rounded-lg marker:bg-neutral-200 p-4 shadow-inner text-neutral-500`;

export const FixedHeightScrollLastChild = (props) => (
  <Contained
    {...props}
    className={clsx(props.className, "scroll-last-child")}
    tw="space-y-6 pb-3"
  />
);

// Typography
export const SectionTitle = tw.div`uppercase text-sm text-primary-600 tracking-widest mb-2 font-bold`;
export const PageTitle = tw.h2`font-medium text-neutral-700 text-2xl max-w-prose`;

export const TableLoading = ({ isNextLoading }) => (
  <div tw="w-full h-1 absolute bottom-0 left-0">
    {isNextLoading && <LinearProgress />}
  </div>
);

export const StyledTabs = forwardRef((props, ref) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    ref={ref}
    {...props}
    css={{
      "&": tw`min-h-0 bg-white rounded-lg shadow-sm`,
      ".MuiTabs-indicator": tw`top-0 bottom-0 h-auto rounded-lg -z-0`,
      ".MuiTab-root": tw`relative z-10 min-h-0 py-3 transition`,
      ".Mui-selected": tw`text-white! delay-300`,
    }}
  />
));

export const StyledTab = forwardRef(({ allow, deny, hide, ...props }, ref) => {
  const { role } = useSelector((state) => state.currentUser);
  const hidden =
    hide ||
    !((!allow?.length || allow?.includes(role)) && !deny?.includes(role));

  return (
    <Tab ref={ref} component={Link} css={hidden && tw`hidden`} {...props} />
  );
});

export const DashboardGrid = tw.div`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 md:gap-6`;
