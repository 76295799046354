/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useWatch } from "react-hook-form";

import { AvatarGroup } from "@mui/material";

import _ from "lodash";

import { useConceptBoardContext } from "@features/conceptBoards";
import { SupplierAvatar } from "@utils/avatar";

import { useFilterContext } from "../filterContext";

const Chip = () => {
  const { setValue, control, setFilterValues, getValues } = useFilterContext();
  const supplierIds = useWatch({ control, name: "suppliers" }) ?? [];
  const { suppliers } = useConceptBoardContext();

  const handleClick = (id) => {
    setValue("suppliers", _.xor(supplierIds, [id]));
    setFilterValues(getValues());
  };

  return (
    <AvatarGroup tw="h-10 items-center" spacing={12}>
      {_.sortBy(suppliers, "name").map((supplier, i) => (
        <SupplierAvatar
          key={i}
          name={supplier.name}
          onClick={() => handleClick(supplier.id)}
          css={[
            tw`cursor-pointer w-9 h-9 hover:z-20`,
            supplierIds.includes(supplier.id) &&
              tw`z-10 outline outline-2 outline-primary-600 outline-offset-0`,
          ]}
        />
      ))}
    </AvatarGroup>
  );
};

const filterConceptSuppliers = {
  name: "conceptSuppliers",
  formControls: ["suppliers"],
  menuText: "Suppliers",
  Chip: Chip,
  alwaysShow: true,
};

export default filterConceptSuppliers;
