import { useSelector } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UploadInfo } from "cloudinary-core";

import { conceptsKeyFactory } from "@features/concepts";
import { CloudinaryFile } from "@models/CloudinaryFile";
import { Concept } from "@models/Concept";
import client from "@services/api";

type CreateCloudinaryFileMutation = {
  fileInfo: UploadInfo;
  requestForQuoteId?: string;
  conceptId?: string;
};

export const useCreateCloudinaryFileMutation = () => {
  const queryClient = useQueryClient();
  const userId = useSelector((state: any) => state.currentUser.id);
  return useMutation({
    mutationFn: ({ fileInfo, ...data }: CreateCloudinaryFileMutation) =>
      client
        .post<CloudinaryFile>("cloudinary-files", {
          cloudinaryId: fileInfo.public_id,
          fileName: fileInfo.original_filename,
          format: fileInfo.format ?? fileInfo.public_id.split(".").at(-1),
          resourceType: fileInfo.resource_type,
          pdfPageCount: fileInfo.pages ?? 0,

          ownerId: userId,
          ...data,
        })
        .then((res) => res.data),
    onSuccess: async (file, { conceptId }) => {
      if (conceptId) {
        // if it's the child of a concept, update the concept with the new file
        queryClient.invalidateQueries({
          queryKey: conceptsKeyFactory.list._def,
        });
        queryClient.setQueryData<Concept>(
          conceptsKeyFactory.detail(conceptId).queryKey,
          (concept) =>
            concept && {
              ...concept,
              cloudinaryFiles: [...concept.cloudinaryFiles, file],
            }
        );
      }
    },
  });
};

type DeleteCloudinaryFilePayload = {
  id: string;
  conceptId?: string;
};

export const useDeleteCloudinaryFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: DeleteCloudinaryFilePayload) =>
      client.delete(`cloudinary-files/${id}`),
    onSuccess(_, { id, conceptId }) {
      if (conceptId) {
        queryClient.invalidateQueries({
          queryKey: conceptsKeyFactory.list._def,
        });
        queryClient.setQueryData<Concept>(
          conceptsKeyFactory.detail(conceptId).queryKey,
          (concept) =>
            concept && {
              ...concept,
              cloudinaryFiles: concept.cloudinaryFiles.filter(
                (file) => file.id !== id
              ),
            }
        );
      }
    },
  });
};
