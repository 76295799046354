/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import ClickAwayListener from "react-advanced-click-away";
import { Control, Controller, useWatch } from "react-hook-form";

import { Add } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  InputBase,
  Popper,
} from "@mui/material";

import _ from "lodash";

import {
  useConceptCategoriesQuery,
  useCreateConceptCategoryMutation,
} from "@features/conceptBoards";
import { StyledPopperComponent } from "@features/filters";
import { RectChip } from "@features/ui";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ConceptCategoryAutocomplete = ({ control, handleChange }) => {
  const createCategoryMutation = useCreateConceptCategoryMutation();
  const initialValues = useWatch({ name: "conceptCategoryIds", control });
  const { data: categories = [] } = useConceptCategoriesQuery({});
  const [value, setValue] = React.useState<string[]>(initialValues);
  return (
    <ClickAwayListener onClickAway={() => handleChange(value)}>
      <div tw="bg-white rounded-lg shadow-lg">
        <Autocomplete
          value={value as any[]}
          open
          size="small"
          disableCloseOnSelect
          onChange={async (event, newValue: any[]) => {
            setValue(newValue.map((v) => v.id ?? v));
          }}
          filterOptions={(options, params) => {
            const inputValue = params.inputValue.trim();

            const filtered = _(options)
              .filter((option) =>
                inputValue !== ""
                  ? option.name.toLowerCase().includes(inputValue.toLowerCase())
                  : true
              )
              .sortBy(
                (opt) => (initialValues.includes(opt.id) ? 0 : 1),
                (option) => option.name.toLowerCase()
              )
              .value();

            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue.toLowerCase() === option.name.toLowerCase()
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                id: "",
                name: `Add "${inputValue}"`,
                inputValue,
              } as any);
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={categories}
          getOptionLabel={(option: any) => {
            return categories.find((c) => c.id === option)?.name ?? "";
          }}
          renderOption={(props: any, option, { selected }) => (
            <li
              {...props}
              tw="py-0! px-0!"
              key={option.id} // override onClick to create the new value
              {...(option.inputValue && {
                onClick: async function (e) {
                  e.stopPropagation();
                  const createdCat = await createCategoryMutation.mutateAsync(
                    option.inputValue
                  );
                  setValue([...value, createdCat.id]);
                },
              })}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              <span tw="whitespace-nowrap pr-3">{option.name}</span>
              {option.inputValue && createCategoryMutation.isPending && (
                <CircularProgress size={16} sx={{ ml: 2 }} />
              )}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.id === value}
          disableClearable
          getOptionDisabled={(opt) =>
            opt.inputValue && createCategoryMutation.isPending
          }
          multiple
          renderInput={(params) => {
            return (
              <InputBase
                tw="p-3 z-[1450]"
                autoFocus
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder="Search Categories"
              />
            );
          }}
          PopperComponent={StyledPopperComponent}
        />
      </div>
    </ClickAwayListener>
  );
};

const ConceptCategoriesSelector = ({
  control,
  canEdit,
  onChange,
}: {
  control: Control<any>;
  canEdit: boolean;
  onChange: () => void;
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (menuAnchorEl) return;
    setMenuAnchorEl(event.currentTarget);
  };
  const { data: categories = [] } = useConceptCategoriesQuery({});

  return (
    <Controller
      control={control}
      name="conceptCategoryIds"
      render={({ field }) => {
        return (
          <>
            {field.value.map((id) => (
              <RectChip
                key={id}
                onDelete={
                  canEdit
                    ? () => {
                        const newValue = field.value.filter((v) => v !== id);
                        field.onChange(newValue);
                        onChange();
                      }
                    : undefined
                }
              >
                {categories.find((c) => c.id === id)?.name}
              </RectChip>
            ))}
            {canEdit && (
              <>
                <RectChip
                  key="add-category-btn"
                  tw="bg-neutral-100 text-neutral-500 hover:bg-neutral-200"
                  onClick={handleOpenMenu}
                >
                  <Add tw="text-xl" />
                </RectChip>
                {menuAnchorEl && (
                  <Popper
                    key="add-category-menu"
                    open
                    anchorEl={menuAnchorEl}
                    placement="bottom-start"
                    tw="pt-1"
                  >
                    <ConceptCategoryAutocomplete
                      control={control}
                      handleChange={(newValue) => {
                        field.onChange(newValue);
                        onChange();
                        handleCloseMenu();
                      }}
                    />
                  </Popper>
                )}
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default ConceptCategoriesSelector;
