/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useParams } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";

import { ConceptOverview, ConceptProvider } from "@features/concepts";
import { FaIcon } from "@features/ui";
import { useSetHash } from "@services/reactRouterDom";

import BoardOverview from "./BoardOverview";

const WorkspaceOverview = () => {
  const { conceptId } = useParams();
  const setHash = useSetHash();
  if (conceptId) {
    return (
      <ConceptProvider conceptId={conceptId}>
        <div tw="relative">
          <ConceptOverview />
          <Tooltip title="Open Concept View">
            <IconButton
              onClick={() => setHash(conceptId)}
              tw="absolute -top-2 -right-2"
            >
              <FaIcon
                icon="up-right-and-down-left-from-center"
                tw="text-base text-primary-800"
                variant="solid"
              />
            </IconButton>
          </Tooltip>
        </div>
      </ConceptProvider>
    );
  }
  return <BoardOverview />;
};

export default WorkspaceOverview;
