import { CloudinaryFile } from "@models/CloudinaryFile";
import cloudinary from "@services/cloudinary";

type options = {
  forDownload?: boolean;
};

export default function getCloudinaryFileUrl(
  file: CloudinaryFile,
  options?: options
) {
  const forDownload = options?.forDownload ?? true;
  return cloudinary.url(file.cloudinaryId, {
    resource_type: file.resourceType,
    secure: true,

    ...(forDownload && {
      flags: `attachment:${encodeURIComponent(
        file.fileName.replace(/[().]/g, "")
      )}`,
    }),
  });
}
