/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { Add } from "@mui/icons-material";

import { StyledButton } from "@components/StyledComponents";
import {
  FileCard,
  useCreateCloudinaryFileMutation,
  useDeleteCloudinaryFileMutation,
} from "@features/cloudinaryFiles";
import { useOpenCloudinaryWidget } from "@services/cloudinary";

import { useConceptContext } from "../ConceptContext";

const ConceptFiles = ({ setInitialFilePreview }) => {
  const { id, cloudinaryFiles } = useConceptContext();
  const openCloudinaryWidget = useOpenCloudinaryWidget();
  const createCloudinaryFileMutation = useCreateCloudinaryFileMutation();
  const deleteCloudinaryFileMutation = useDeleteCloudinaryFileMutation();

  const handleUploadClick = (e) => {
    e.preventDefault();
    openCloudinaryWidget({
      onSuccess: (file) => {
        createCloudinaryFileMutation.mutate({
          fileInfo: file.info,
          conceptId: id,
        });
      },
    });
  };

  const handleFileDelete = (fileId: string) => {
    deleteCloudinaryFileMutation.mutate({
      id: fileId,
      conceptId: id,
    });
  };

  return (
    <div>
      <div tw="flex justify-between items-center mb-3">
        <h2 tw="text-lg font-medium text-neutral-700">Files</h2>
        <StyledButton
          outlined
          size="small"
          onClick={handleUploadClick}
          endIcon={<Add />}
        >
          Upload File
        </StyledButton>
      </div>

      <div tw="flex flex-wrap gap-4">
        {cloudinaryFiles.map((file, i) => (
          <FileCard
            key={file.id}
            file={file}
            onDelete={handleFileDelete}
            onClick={() => setInitialFilePreview(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default ConceptFiles;
