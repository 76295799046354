/** @jsxImportSource @emotion/react */
import "twin.macro";

import {
  ConceptBoardForm,
  ConceptBoardProvider,
} from "@features/conceptBoards";

const ConceptBoardEdit = () => {
  return (
    <ConceptBoardProvider>
      <ConceptBoardForm />
    </ConceptBoardProvider>
  );
};

export default ConceptBoardEdit;
