import { ReactElement, ReactNode } from "react";

import { sortBy } from "lodash";

import { useApiResource } from "@services/api";

import ActiveStatusFilter from "./components/ActiveStatusFilter";
import BudgetFilter from "./components/BudgetFilter";
import DateRange from "./components/DateRangeFilter";
import FavoritesFilter from "./components/FavoritesFilter";
import GroupsFilter from "./components/GroupsFilter";
import InStockFilter from "./components/InStockFilter";
import IsActiveFilter from "./components/IsActiveFilter";
import OrderGroupBy from "./components/OrderGroupBy";
import OrderSetStatusFilter from "./components/OrderSetStatusFilter";
import OrderStatusFilter from "./components/OrderStatusFilter";
import OrderTypeFilter from "./components/OrderTypeFilter";
import OrderWindowFilter from "./components/OrderWindowFilter";
import ProgramFilter from "./components/ProgramFilter";
import PurchaseOrderStatusFilter from "./components/PurchaseOrderStatusFilter";
import PurchasersFilter from "./components/PurchasersFilter";
import QuoteStatusFilter from "./components/QuoteStatusFilter";
import Search from "./components/Search";
import StateFilter from "./components/StateFilter";
import SupplierFilter from "./components/SuppliersFilter";
import TerritoryFilter from "./components/TerritoryFilter";
import UserActiveStatusFilter from "./components/UserActiveStatusFilter";
import UserFilter from "./components/UserFilter";
import UserRolesFilter from "./components/UserRolesFilter";
import filterConceptFavorites from "./components/filterConceptFavorites";
import filterConceptSuppliers from "./components/filterConceptSuppliers";

type Filter = {
  name: string;
  uniqueId?: string;
  formControls?: string[];
  menuText?: ReactNode;
  Chip: (x: any) => ReactElement | null;
  Popper?: (x: any) => ReactElement | null;
  isArray?: boolean;
  alwaysShow?: boolean;
  props?: Record<string, any>;
};

const useFilterOptions = () => {
  const groupCategories = sortBy(
    useApiResource("group-categories", { revalidate: false }).data ?? [],
    "priorityLevel"
  );

  const filterOptions: Filter[] = [
    {
      name: "search",
      uniqueId: "q",
      Chip: (props) => <Search {...props} />,
      alwaysShow: true,
    },
    {
      name: "favorites",
      menuText: <FavoritesFilter.MenuItem />,
      Chip: FavoritesFilter.Tile,
    },
    {
      name: "territories",
      uniqueId: "territories",
      menuText: "Territories",
      Chip: TerritoryFilter.Chip,
      Popper: TerritoryFilter.Popper,
      isArray: true,
    },
    {
      name: "orderType",
      menuText: "Order Type",
      Chip: OrderTypeFilter.Chip,
      Popper: OrderTypeFilter.Popper,
    },
    ...groupCategories.map((gc) => ({
      name: "groups",
      uniqueId: `groups.${gc.id}`,
      menuText: gc.name,
      props: { category: gc },
      Popper: GroupsFilter.Popper,
      Chip: GroupsFilter.Tile,
      isArray: true,
    })),
    {
      name: "programs",
      menuText: "Programs",
      Chip: ProgramFilter.Tile,
      Popper: ProgramFilter.Popper,
      isArray: true,
    },
    {
      name: "users",
      menuText: "Users",
      Chip: UserFilter.Chip,
      Popper: UserFilter.Popper,
      isArray: true,
    },
    {
      name: "orderGroupBy",
      Chip: OrderGroupBy,
      alwaysShow: true,
    },
    {
      name: "dateRange",
      formControls: ["fromDate", "toDate"],
      Chip: DateRange.Chip,
      Popper: DateRange.Popper,
      menuText: "Date Range",
    },
    {
      name: "orderStatus",
      Chip: OrderStatusFilter.Chip,
      Popper: OrderStatusFilter.Popper,
      menuText: "Order Status",
    },
    {
      name: "purchaseOrderStatus",
      Chip: PurchaseOrderStatusFilter.Chip,
      Popper: PurchaseOrderStatusFilter.Popper,
      menuText: "Purchase Order Status",
    },
    {
      name: "quoteStatus",
      formControls: ["status"],
      Chip: QuoteStatusFilter.Chip,
      Popper: QuoteStatusFilter.Popper,
      menuText: "Quote Status",
    },
    {
      name: "inStock",
      Chip: InStockFilter.Chip,
      menuText: <InStockFilter.MenuItem />,
    },
    {
      name: "isActive",
      Chip: IsActiveFilter.Chip,
      menuText: <IsActiveFilter.MenuItem />,
    },
    {
      name: "activeStatus",
      Chip: ActiveStatusFilter.Chip,
      Popper: ActiveStatusFilter.Popper,
      menuText: "Active Status",
    },
    {
      name: "orderWindows",
      Chip: OrderWindowFilter.Chip,
      Popper: OrderWindowFilter.Popper,
      menuText: "Order Windows",
    },
    {
      name: "suppliers",
      uniqueId: "suppliers",
      menuText: "Suppliers",
      Chip: SupplierFilter.Chip,
      Popper: SupplierFilter.Popper,
      isArray: true,
    },
    {
      name: "purchasers",
      uniqueId: "purchasers",
      menuText: "Purchasers",
      Chip: PurchasersFilter.Chip,
      Popper: PurchasersFilter.Popper,
      isArray: true,
    },
    {
      name: "states",
      Chip: StateFilter.Chip,
      Popper: StateFilter.Popper,
      menuText: "States",
    },
    {
      name: "orderSetStatus",
      Chip: OrderSetStatusFilter.Chip,
      Popper: OrderSetStatusFilter.Popper,
      menuText: "Order-Set Status",
    },
    {
      name: "userRoles",
      uniqueId: "userRoles",
      menuText: "User Roles",
      Chip: UserRolesFilter.Chip,
      Popper: UserRolesFilter.Popper,
      isArray: true,
    },
    {
      name: "userActiveStatus",
      Chip: UserActiveStatusFilter.Chip,
      Popper: UserActiveStatusFilter.Popper,
      menuText: "Active Status",
    },
    {
      name: "budgets",
      Chip: BudgetFilter.Chip,
      Popper: BudgetFilter.Popper,
      menuText: "Budgets",
    },
    filterConceptSuppliers,
    filterConceptFavorites,
  ];

  return filterOptions.map((f) => ({
    ...f,
    uniqueId: f.uniqueId ?? f.name,
    formControls: f.formControls ?? [f.uniqueId ?? f.name],
  }));
};

export default useFilterOptions;
